.contrast-on .banner-section .button-outer a {
    display: inline-block;
    padding: 14px 30px;
    font-size: 16px;
    font-family: 'Canaro-Book';
    border: 2px solid #01CAF3;
    background-color: transparent;
    -ms-border-radius: 30px;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    text-decoration: none;
    line-height: 1.4;
    color: #fff
}
.text-center{
    align-items: center;
    text-align: center;
    padding: 10px;
}
.contrast-on .banner-section .button-outer a:hover,
.contrast-on .banner-section .button-outer a:focus {
    background-color: #01CAF3;
    color: #fff
}
.contrast-on .banner-section .banner-inner .right-image-video .right-image-inner::before {
    display: none
}
.banner-section {
    padding-top: 40px
}
.text-center{
	color:#fff;	
	text-transform:uppercase;
    font-size: 20px;
    margin: -5px 0 50px 0;
    display: block;
    text-align: center;
}
.input-container{
	position:relative;
	margin-bottom:25px;
}
.input-container label{
	position:absolute;
	top:0px;
	left:0px;
	font-size:16px;
	color:#fff;	
    pointer-events:none;
	transition: all 0.5s ease-in-out;
}
.input-container input{ 
  border:0;
  border-bottom:1px solid #555;  
  background:transparent;
  width:100%;
  padding:8px 0 5px 0;
  font-size:16px;
  color:#fff;
}
.btn{
	color:#fff;
	background-color:#01CAF3;
	outline: none;
    border:0;
    color: #fff;
	padding:10px 20px;
	text-transform:uppercase;
	margin-top:10px;
	border-radius:12px;
	cursor:pointer;
    left: 25%;
	position:relative;
}
.input-container input:focus{ 
 border:none;	
 outline:none;
 border-bottom:1px solid #5a83ea;	
}
.input-container input:focus ~ label,
.input-container input:valid ~ label{
	top:-12px;
	font-size:12px;
	
}
@media screen and (min-width: 768px) {
    .banner-section {
        padding-top: 50px
    }
}
@media screen and (min-width: 992px) {
    .banner-section {
        padding-top: 70px
    }
}
@media screen and (min-width: 1200px) {
    .banner-section {
        padding-top: 85px
    }
}
@media screen and (max-width: 767px) {
    .banner-section .banner-inner {
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-direction: column-reverse;
        -webkit-flex-direction: column-reverse;
        flex-direction: column-reverse
    }
}
@media screen and (min-width: 768px) {
    .banner-section .banner-inner {
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        -webkit-align-items: center;
        -ms-align-items: center;
        -o-align-items: center
    }
}
.banner-section .banner-inner .left-text {
    width: 100%;
    position: relative;
    z-index: 9
}
@media screen and (max-width: 767px) {
    .banner-section .banner-inner .left-text {
        padding-top: 30px;
        text-align: center
    }
}
@media screen and (min-width: 768px) {
    .banner-section .banner-inner .left-text {
        padding-right: 15px;
        width: 50%
    }
}
@media screen and (min-width: 992px) {
    .banner-section .banner-inner .left-text {
        padding-right: 20px
    }
}
@media screen and (min-width: 1200px) {
    .banner-section .banner-inner .left-text {
        padding-right: 50px
    }
}
@media screen and (min-width: 1600px) {
    .banner-section .banner-inner .left-text {
        padding-right: 55px
    }
}
@media screen and (min-width: 1900px) {
    .banner-section .banner-inner .left-text {
        padding-right: 65px
    }
}
.banner-section .banner-inner .left-text h1 {
    margin: 0px;
    font-size: 28px;
    font-family: 'Canaro-Bold';
    color: #fff;
    position: relative;
    line-height: 1.1
}
.banner-section .banner-inner .left-text h1:after {
    content: "";
    display: block;
    width: 115px;
    background-color: #01CAF3;
    height: 7px;
    margin-top: 15px
}
@media screen and (max-width: 767px) {
    .banner-section .banner-inner .left-text h1:after {
        margin: auto;
        margin-top: 15px
    }
}
@media screen and (min-width: 768px) {
    .banner-section .banner-inner .left-text h1 {
        font-size: 36px
    }
    .banner-section .banner-inner .left-text h1:after {
        margin-top: 25px
    }
}
@media screen and (min-width: 992px) {
    .banner-section .banner-inner .left-text h1 {
        font-size: 42px
    }
}
@media screen and (min-width: 1200px) {
    .banner-section .banner-inner .left-text h1 {
        font-size: 50px;
        max-width: 450px
    }
}
@media screen and (min-width: 1600px) {
    .banner-section .banner-inner .left-text h1 {
        font-size: 56px
    }
}
@media screen and (min-width: 1900px) {
    .banner-section .banner-inner .left-text h1 {
        font-size: 62px
    }
}
.banner-section .banner-inner .left-text h1 span {
    color: #01CAF3;
    display: block
}
.banner-section .banner-inner .left-text .button-wrapper {
    margin-top: 20px
}
@media screen and (min-width: 992px) {
    .banner-section .banner-inner .left-text .button-wrapper {
        margin-top: 30px
    }
}
.banner-section .banner-inner .left-text .button-wrapper p {
    font-size: 16px;
    margin-bottom: 20px;
    line-height: 1.3
}
@media screen and (min-width: 768px) {
    .banner-section .banner-inner .left-text .button-wrapper p {
        margin-bottom: 30px;
        max-width: 400px;
        font-size: 18px
    }
}
@media screen and (min-width: 992px) {
    .banner-section .banner-inner .left-text .button-wrapper p {
        margin-bottom: 35px;
        max-width: 450px;
        font-size: 20px
    }
}
@media screen and (min-width: 1200px) {
    .banner-section .banner-inner .left-text .button-wrapper p {
        font-size: 22px;
        margin-bottom: 40px;
        max-width: 500px
    }
}
@media screen and (min-width: 1600px) {
    .banner-section .banner-inner .left-text .button-wrapper p {
        margin-bottom: 45px;
        max-width: 600px
    }
}
@media screen and (min-width: 1900px) {
    .banner-section .banner-inner .left-text .button-wrapper p {
        margin-bottom: 50px
    }
}
@media screen and (min-width: 1200px) {
    .banner-section .banner-inner .left-text .button-wrapper .button-outer {
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex
    }
}
@media screen and (max-width: 1199px) {
    .banner-section .banner-inner .left-text .button-wrapper .button-outer .button2 {
        margin-top: 15px
    }
}
.banner-section .banner-inner .right-image-video {
    width: 100%
}
@media screen and (min-width: 768px) {
    .banner-section .banner-inner .right-image-video {
        width: 50%;
        padding-left: 15px
    }
}
@media screen and (min-width: 992px) {
    .banner-section .banner-inner .right-image-video {
        padding-left: 20px
    }
}
@media screen and (min-width: 1200px) {
    .banner-section .banner-inner .right-image-video {
        padding-left: 50px
    }
}
@media screen and (min-width: 1600px) {
    .banner-section .banner-inner .right-image-video {
        padding-left: 55px
    }
}
@media screen and (min-width: 1900px) {
    .banner-section .banner-inner .right-image-video {
        padding-left: 65px
    }
}
.banner-section .banner-inner .right-image-video .right-image-inner {
    position: relative
}
@media screen and (min-width: 768px) {
    .banner-section .banner-inner .right-image-video .right-image-inner:before {
        content: "";
        position: absolute;
        width: 410%;
        height: 410%;
        left: 50%;
        top: 50%;
        background-image: url("../../../public/assets/images/banner-top.png");
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        z-index: 1
    }
}
.banner-section .banner-inner .right-image-video .image {
    position: relative;
    overflow: hidden;
    border-radius: 50%
}
.banner-section .banner-inner .right-image-video .image img {
    position: relative;
    z-index: 1
}
.banner-section .banner-inner .right-image-video .image svg {
    position: relative;
    z-index: 9;
    color: #000040;
    border-radius: 50%;
    width: 100%;
    height: auto;
    display: block
}
.banner-section .banner-inner .right-image-video .image .video {
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    top: 1px;
    left: 1px;
    right: 0px;
    margin: auto;
    display: block;
    border-radius: 50%;
    overflow: hidden
}