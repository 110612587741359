
 .our-values-section {
    padding-top: 50px;
    padding-bottom: 40px;
    position: relative;
    z-index: 1
}
@media screen and (min-width: 768px) {
     .our-values-section {
        padding-top: 75px;
        padding-bottom: 75px
    }
}
@media screen and (min-width: 992px) {
     .our-values-section {
        padding-top: 95px;
        padding-bottom: 95px
    }
}
@media screen and (min-width: 1200px) {
     .our-values-section {
        padding-top: 110px;
        padding-bottom: 110px
    }
}
@media screen and (min-width: 768px) {
     .our-values-section:before {
        content: "";
        position: absolute;
        width: 1502px;
        height: 1155px;
        left: -50px;
        top: -50px;
        background: #000040;
        background-repeat: no-repeat;
        background-image: url("../../../public/assets/images/lueur2.png");
        background-position: left bottom;
        background-blend-mode: screen;
        background-size: contain;
        z-index: 2
    }
     .our-values-section:after {
        position: relative;
        border-width: 0 0 260px 260px;
        border-style: solid;
        content: "";
        display: block;
        border-color: #00064a;
        position: absolute;
        height: calc(100% + 50px);
        width: calc(50vw + 110px);
        border-radius: 0 0 0 340px;
        left: calc(50% - 90px);
        bottom: -404px;
        z-index: 2
    }
}
 .our-values-section .our-values-inner {
    position: relative;
    z-index: 3
}
 .our-values-section h2 {
    margin: 0px;
    font-size: 26px;
    font-family: 'Canaro-Bold';
    color: #fff;
    position: relative;
    line-height: 1.1;
    z-index: 1
}
@media screen and (max-width: 767px) {
     .our-values-section h2 {
        text-align: center
    }
}
 .our-values-section h2:before {
    content: "02";
    display: block;
    color: #0a1650;
    position: absolute;
    font-family: 'Canaro-Bold';
    z-index: -1;
    left: 0px;
    top: -65px;
    font-size: 120px
}
@media screen and (max-width: 767px) {
     .our-values-section h2:before {
        right: 0px;
        margin: auto
    }
}
@media screen and (min-width: 768px) {
     .our-values-section h2:before {
        font-size: 150px;
        top: -85px;
        left: -25px
    }
}
@media screen and (min-width: 992px) {
     .our-values-section h2:before {
        font-size: 170px;
        top: -85px;
        left: -35px
    }
}
@media screen and (min-width: 1200px) {
     .our-values-section h2:before {
        font-size: 190px;
        top: -85px;
        left: -95px
    }
}
 .our-values-section h2:after {
    content: "";
    display: block;
    width: 75px;
    background-color: #01CAF3;
    height: 5px;
    margin-top: 15px
}
@media screen and (max-width: 767px) {
     .our-values-section h2:after {
        margin: auto;
        margin-top: 15px
    }
}
@media screen and (min-width: 768px) {
     .our-values-section h2 {
        font-size: 34px
    }
}
@media screen and (min-width: 992px) {
     .our-values-section h2 {
        font-size: 42px
    }
}
@media screen and (min-width: 1200px) {
     .our-values-section h2 {
        font-size: 51px
    }
}
 .our-values-section .our-value-items {
    padding-top: 30px
}
@media screen and (min-width: 768px) {
     .our-values-section .our-value-items {
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        padding-top: 60px;
        margin: 0 -15px
    }
}
@media screen and (min-width: 992px) {
     .our-values-section .our-value-items {
        padding-top: 90px
    }
}
 .our-values-section .our-value-items .our-value-item {
    width: 100%
}
@media screen and (max-width: 991px) {
     .our-values-section .our-value-items .our-value-item {
        margin-bottom: 25px
    }
}
@media screen and (min-width: 768px) {
     .our-values-section .our-value-items .our-value-item {
        width: 50%;
        padding: 0 15px
    }
}
@media screen and (min-width: 992px) {
     .our-values-section .our-value-items .our-value-item {
        width: 33.3333%
    }
}
 .our-values-section .our-value-items .our-value-item .our-value-inner {
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 30px 15px;
    height: 100%
}
@media screen and (min-width: 768px) {
     .our-values-section .our-value-items .our-value-item .our-value-inner {
        padding: 40px
    }
}
 .our-values-section .our-value-items .our-value-item .our-value-inner .icon {
    height: 70px
}
 .our-values-section .our-value-items .our-value-item .our-value-inner .icon img {
    max-width: 70px
}
 .our-values-section .our-value-items .our-value-item .our-value-inner .text {
    margin-top: 30px
}
@media screen and (min-width: 768px) {
     .our-values-section .our-value-items .our-value-item .our-value-inner .text {
        margin-top: 45px
    }
}
 .our-values-section .our-value-items .our-value-item .our-value-inner .text h3 {
    margin: 0px;
    font-size: 24px;
    color: #fff;
    font-family: 'Canaro-SemiBold';
    line-height: 1.1;
    padding-bottom: 30px
}
 .our-values-section .our-value-items .our-value-item .our-value-inner .text h3:after {
    content: "";
    display: block;
    width: 56px;
    background-color: #01CAF3;
    height: 4px;
    margin-top: 15px
}
@media screen and (min-width: 768px) {
     .our-values-section .our-value-items .our-value-item .our-value-inner .text h3 {
        font-size: 27px
    }
}
 .our-values-section .our-value-items .our-value-item .our-value-inner .text p {
    font-size: 16px
}
 .our-values-section .our-value-items .our-value-item:nth-child(1) .our-value-inner {
    background-image: url("../../../public/assets/images/bloc1.png")
}
 .our-values-section .our-value-items .our-value-item:nth-child(2) .our-value-inner {
    background-image: url("../../../public/assets/images/bloc-fond2.png")
}
 .our-values-section .our-value-items .our-value-item:nth-child(3) .our-value-inner {
    background-image: url("../../../public/assets/images/bloc-fond3.png")
}