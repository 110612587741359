 .contact-us-section {
    padding-top: 70px;
    padding-bottom: 40px;
    text-align: center;
    position: relative;
    z-index: 2
}
@media screen and (min-width: 768px) {
     .contact-us-section {
        padding-top: 75px;
        padding-bottom: 75px
    }
}
@media screen and (min-width: 992px) {
     .contact-us-section {
        padding-top: 95px;
        padding-bottom: 95px
    }
}
@media screen and (min-width: 1200px) {
     .contact-us-section {
        padding-top: 110px;
        padding-bottom: 110px
    }
}
 .contact-us-section h2 {
    margin: 0px;
    font-size: 26px;
    font-family: 'Canaro-Bold';
    color: #fff;
    position: relative;
    line-height: 1.1;
    padding-bottom: 25px;
    z-index: 1
}
 .contact-us-section h2:before {
    content: "07";
    display: block;
    color: #0a1650;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    font-family: 'Canaro-Bold';
    z-index: -1;
    top: -65px;
    font-size: 120px
}
@media screen and (min-width: 768px) {
     .contact-us-section h2:before {
        font-size: 150px;
        top: -85px
    }
}
@media screen and (min-width: 992px) {
     .contact-us-section h2:before {
        font-size: 170px;
        top: -85px
    }
}
@media screen and (min-width: 1200px) {
     .contact-us-section h2:before {
        font-size: 190px;
        top: -105px
    }
}
 .contact-us-section h2:after {
    content: "";
    display: block;
    width: 75px;
    background-color: #01CAF3;
    height: 5px;
    margin: auto;
    margin-top: 15px
}
@media screen and (min-width: 768px) {
     .contact-us-section h2 {
        font-size: 34px
    }
}
@media screen and (min-width: 992px) {
     .contact-us-section h2 {
        font-size: 42px
    }
}
@media screen and (min-width: 1200px) {
     .contact-us-section h2 {
        font-size: 51px
    }
}
 .contact-us-section .icon {
    padding-top: 10px
}
 .contact-us-section p {
    font-size: 16px;
    margin-bottom: 0px;
    padding-top: 10px
}
@media screen and (min-width: 768px) {
     .contact-us-section p {
        max-width: 700px;
        margin: auto;
        padding-top: 25px
    }
}
 .contact-us-section .button2 {
    padding-top: 20px
}
@media screen and (min-width: 768px) {
     .contact-us-section .button2 {
        padding-top: 35px
    }
}
 .contact-us-section .button2 a {
    background-color: #000040
}
 .contact-us-section .button2 a:hover,
 .contact-us-section .button2 a:focus {
    background-color: #01CAF3;
    color: #fff
}