* {
  font-family: "Canaro-Book", "Montserrat" !important;
}
.header-fixed .header-top{
  display: none;
}
.header-fixed .header-bottom{
  margin: 0;
}
.header-top .container-fluid{
  position: absolute;
}
.header-bottom{
  margin-top: 40px;
}
.header{
  padding-bottom: 10px !important;
}
.logo-banner2{
  display: none;
}
.contrast-on .logo-banner2{
  display: block;
}
.contrast-on .logo-banner{
  display: none;
}
.language-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.language-dropdown > select {
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  border: 1px solid #01caf3;
  color: white;
  padding: 8px 15px;
  border-radius: 20px;
  outline: none;
  padding-right: 25px;
  font-size: 12px;
}
.language-dropdown::after {
  content: "▼";
  position: absolute;
  top: 50%;
  font-size: 9px;
  right: 13px;
  transform: translateY(-50%);
  color: white;
  pointer-events: none;
}
.contrast-on .language-dropdown::after {
  color: black;
}
.contrast-on .language-dropdown > select {
  color: black;
}

.homepage-content-wrapper {
  padding-top: 40px;
  padding-bottom: 40px;
  position: relative;
  z-index: 2;
}
.page-content-wrapper {
  padding-top: 117.5px;
}
@media screen and (min-width: 768px) {
  .page-content-wrapper {
    padding-top: 116.5px;
  }
}
@media screen and (min-width: 992px) {
  .page-content-wrapper {
    padding-top: 118px;
  }
}
@media screen and (min-width: 1200px) {
  .page-content-wrapper {
    padding-top: 124.5px;
  }
}
.header-fixed .scroll {
  display: none;
}
.single-news .page-content-wrapper,
.single-careers .page-content-wrapper,
.home .page-content-wrapper {
  padding-top: 0px;
}
.home .page-content-wrapper:after,
.home .page-content-wrapper:before {
  display: none;
}
@media screen and (min-width: 768px) {
  .page-wrapper {
    background-color: #000040;
    background-image: url("../public/assets/images/Lueur1.png");
    background-blend-mode: screen;
    background-repeat: no-repeat;
    background-position: top left;
  }
}
@media screen and (min-width: 768px) {
  .home .page-content-wrapper {
    position: relative;
    overflow: hidden;
  }
  .home .page-content-wrapper:after {
    content: "";
    position: absolute;
    width: 1923px;
    height: 1731px;
    right: calc(50% - 188px);
    bottom: -110px;
    background-repeat: no-repeat;
    background: #000040;
    background-image: url("../public/assets/images/lueur5.png");
    background-position: left bottom;
    background-size: contain;
    z-index: 1;
    min-width: calc(50% + 188px);
    background-blend-mode: screen;
  }
  .home .page-content-wrapper:before {
    content: "";
    position: absolute;
    width: 1048px;
    height: 1147px;
    left: 50%;
    bottom: 0px;
    background-repeat: no-repeat;
    background-image: url("../public/assets/images/forme-bas.png");
    background-position: right bottom;
    background-size: contain;
    min-width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .page-template-carrrer-page .page-content-wrapper {
    position: relative;
    overflow: hidden;
  }
  .page-template-carrrer-page .page-content-wrapper:after {
    content: "";
    position: absolute;
    width: 1923px;
    height: 1731px;
    right: calc(50% - 188px);
    bottom: -110px;
    background-repeat: no-repeat;
    background: #000040;
    background-image: url("../public/assets/images/lueur5.png");
    background-position: left bottom;
    background-size: contain;
    z-index: 1;
    min-width: calc(50% + 188px);
    background-blend-mode: screen;
  }
  .page-template-carrrer-page .page-content-wrapper:before {
    content: "";
    position: absolute;
    width: 1048px;
    height: 1147px;
    left: 50%;
    bottom: 0px;
    background-repeat: no-repeat;
    background-image: url("../public/assets/images/forme-bas.png");
    background-position: right bottom;
    background-size: contain;
    min-width: 50%;
  }
}
.single-careers .page-wrapper {
  background-image: none;
}
@media screen and (min-width: 768px) {
  .single-careers .page-content-wrapper {
    position: relative;
    overflow: hidden;
  }
  .single-careers .page-content-wrapper:after {
    content: "";
    position: absolute;
    width: 1203px;
    height: 674px;
    right: calc(50% - 188px);
    bottom: 0px;
    background-repeat: no-repeat;
    background-image: url("../public/assets/images/careen-bottom-left.png");
    background-position: left bottom;
    background-size: contain;
    z-index: 1;
    background-color: transparent;
    background-blend-mode: unset;
  }
  .single-careers .page-content-wrapper:before {
    background-color: transparent;
    background-blend-mode: unset;
    z-index: 2;
    content: "";
    position: absolute;
    width: 1048px;
    height: 1147px;
    left: 50%;
    bottom: 0px;
    background-repeat: no-repeat;
    background-image: url("../public/assets/images/forme-bas.png");
    background-position: right bottom;
    background-size: contain;
    min-width: 50%;
  }
}
.page-template-Contact .page-wrapper {
  background-image: none;
}
@media screen and (min-width: 768px) {
  .page-template-Contact .page-content-wrapper {
    position: relative;
    overflow: hidden;
  }
  .page-template-Contact .page-content-wrapper:after {
    content: "";
    position: absolute;
    width: 822px;
    height: 719px;
    left: 0px;
    bottom: 0px;
    background: transparent;
    background-repeat: no-repeat;
    background-image: url("../public/assets/images/contact-bottom-left.png");
    background-position: left bottom;
    background-blend-mode: screen;
    background-size: contain;
    z-index: 1;
  }
  .page-template-Contact .page-content-wrapper:before {
    display: none;
  }
}
.page-template-news .page-wrapper {
  background-image: none;
}
@media screen and (min-width: 768px) {
  .page-template-news .page-wrapper .page-content-wrapper {
    position: relative;
    overflow: hidden;
  }
  .page-template-news .page-wrapper .page-content-wrapper:after {
    content: "";
    position: absolute;
    width: 1920px;
    height: 903px;
    background-color: #000040;
    bottom: 0px;
    left: 0px;
    background-repeat: no-repeat;
    background-image: url("../public/assets/images/news-last.png");
    background-position: left bottom;
    background-blend-mode: screen;
    background-size: contain;
  }
  .page-template-news .page-wrapper .page-content-wrapper:before {
    content: "";
    position: absolute;
    width: 1304px;
    height: 1522px;
    background-color: transparent;
    bottom: -300px;
    left: 0px;
    background-repeat: no-repeat;
    background-image: url("../public/assets/images/news-final.png");
    background-position: left bottom;
    background-size: contain;
    z-index: 3;
  }
}
.page-template-joinus .page-wrapper {
  background-image: none;
}
.page-template-joinus .page-content-wrapper {
  background-image: url("../public/assets/images/join-middle.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
@media screen and (min-width: 768px) {
  .page-template-joinus .page-content-wrapper {
    position: relative;
    overflow: hidden;
  }
  .page-template-joinus .page-content-wrapper:after {
    background-color: transparent;
    background-image: url("../public/assets/images/join-top.png");
    background-blend-mode: screen;
    background-repeat: no-repeat;
    background-position: top left;
    width: 760px;
    height: 901px;
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    content: "";
    z-index: 2;
  }
  .page-template-joinus .page-content-wrapper:before {
    background-color: transparent;
    background-blend-mode: unset;
    z-index: 2;
    content: "";
    position: absolute;
    width: 1048px;
    height: 1147px;
    left: 50%;
    bottom: 0px;
    background-repeat: no-repeat;
    background-image: url("../public/assets/images/forme-bas.png");
    background-position: right bottom;
    background-size: contain;
    min-width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .single-news .page-content-wrapper {
    position: relative;
    overflow: hidden;
  }
  .single-news .page-content-wrapper:after {
    content: "";
    position: absolute;
    width: 1920px;
    height: 835px;
    left: 0px;
    bottom: 0px;
    background: #000040;
    background-repeat: no-repeat;
    background-image: url("../public/assets/images/news-detail-last.png");
    background-position: left bottom;
    background-blend-mode: screen;
    background-size: contain;
    z-index: 1;
  }
  .single-news .page-content-wrapper:before {
    content: "";
    position: absolute;
    width: 1048px;
    height: 1147px;
    left: 50%;
    bottom: 0px;
    background: #000040;
    background-repeat: no-repeat;
    background-image: url("../public/assets/images/forme-bas.png");
    background-position: left bottom;
    background-blend-mode: screen;
    background-size: contain;
  }
}
@media screen and (min-width: 768px) {
  .page-id-200 .page-content-wrapper,
  .page-id-3 .page-content-wrapper {
    position: relative;
    overflow: hidden;
  }
  .page-id-200 .page-content-wrapper:after,
  .page-id-3 .page-content-wrapper:after {
    content: "";
    position: absolute;
    width: 1923px;
    height: 1731px;
    right: calc(50% - 188px);
    bottom: -110px;
    background-repeat: no-repeat;
    background: #000040;
    background-image: url("../public/assets/images/lueur5.png");
    background-position: left bottom;
    background-size: contain;
    z-index: 1;
    min-width: calc(50% + 188px);
    background-blend-mode: screen;
  }
  .page-id-200 .page-content-wrapper:before,
  .page-id-3 .page-content-wrapper:before {
    content: "";
    position: absolute;
    width: 1048px;
    height: 1147px;
    left: 50%;
    bottom: 0px;
    background-repeat: no-repeat;
    background-image: url("../public/assets/images/forme-bas.png");
    background-position: right bottom;
    background-size: contain;
    min-width: 50%;
  }
}
.button1 {
  padding-right: 20px;
}
.button1 a {
  display: inline-block;
  padding: 14px 30px;
  font-size: 16px;
  font-family: "Canaro-Book" sans-serif;
  border: 2px solid #fff;
  background-color: transparent;
  -ms-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  text-decoration: none;
  color: #fff;
  line-height: 1.4;
  text-align: center;
}
.button1 a:hover,
.button1 a:focus {
  background-color: #fff;
  color: #000040;
}
.button2 a {
  cursor: pointer;
  display: inline-block;
  padding: 14px 30px;
  font-size: 16px;
  font-family: "Canaro-Book" sans-serif;
  border: 2px solid #01caf3;
  background-color: transparent;
  -ms-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  text-decoration: none;
  line-height: 1.4;
  text-align: center;
  color: #fff;
}
.button2 a:hover,
.button2 a:focus {
  background-color: #01caf3;
  color: #fff;
}
.error404 .main-content {
  padding: 50px 0px;
  text-align: center;
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 1600px) {
  .error404 .main-content {
    padding: 150px 0px;
  }
}
.error404 .main-content p {
  font-size: 16px;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 0px;
}
.error404 .main-content .link-bordered {
  margin-top: 30px;
}
.error404 .main-content .link-bordered a {
  display: inline-block;
  padding: 14px 30px;
  font-size: 16px;
  font-family: "Canaro-Book" sans-serif;
  border: 2px solid #01caf3;
  background-color: #000040;
  -ms-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  text-decoration: none;
  line-height: 1.4;
  color: #fff;
}
.error404 .main-content .link-bordered a:hover,
.error404 .main-content .link-bordered a:focus {
  background-color: #01caf3;
  color: #fff;
}
.loader {
  filter: url("#goo");
  position: relative;
  width: 300px;
  height: 70px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto;
}
.loader::after {
  content: "";
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fff;
  margin: 0 auto;
  position: absolute;
  top: 17.5px;
  left: 132.5px;
  animation: scale 2.5s ease-in-out infinite;
}
.loader div {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
  top: 20px;
  left: 135px;
  animation: move 2.5s ease-in-out infinite alternate;
}
.loader div::after,
.loader div::before {
  content: "";
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #fff;
}
.loader div::before {
  left: -75px;
}
.loader div::after {
  left: 75px;
}
@keyframes move {
  0% {
    transform: translateX(-150px);
  }
  100% {
    transform: translateX(150px);
  }
}
@keyframes scale {
  10% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.25);
  }
  90% {
    transform: scale(1);
  }
}
.loader-wrapper {
  background: #000040;
  width: 100%;
  height: 100%;
  min-height: 100vh;
}
.loader-wrapper svg {
  position: absolute;
  z-index: -100;
  pointer-events: none;
}
.loader-main {
  width: 150px;
  height: 70px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: auto;
  filter: none;
  display: none;
}
@media screen and (min-width: 768px) {
  .homepage-content-wrapper {
    position: relative;
    overflow: hidden;
  }
  .homepage-content-wrapper:after {
    content: "";
    position: absolute;
    width: 1923px;
    height: 1731px;
    right: calc(50% - 188px);
    bottom: -110px;
    background-repeat: no-repeat;
    background-image: url("../public/assets/images/lueur5.png");
    background-position: left bottom;
    background-size: contain;
    z-index: 1;
    min-width: calc(50% + 188px);
    background-color: #000040;
    background-blend-mode: screen;
  }
  .homepage-content-wrapper:before {
    content: "";
    position: absolute;
    width: 1048px;
    height: 1147px;
    left: 50%;
    bottom: 0px;
    background-repeat: no-repeat;
    background-image: url("../public/assets/images/forme-bas.png");
    background-position: right bottom;
    background-size: contain;
    min-width: 50%;
  }
}
.contrast-on .header .header-top .accessblity-module .front-resizer .increase,
.contrast-on
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .increasec
  span,
.contrast-on .header .header-top .accessblity-module .front-resizer .reset,
.contrast-on .header .header-top .accessblity-module .front-resizer .decrease,
.contrast-on
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .decrease
  span,
.contrast-on .header .header-inner .main-navigation ul.menu li a,
.contrast-on .header .header-inner .main-navigation ul.menu li a:hover,
.contrast-on
  .header
  .header-top
  .contrast
  .contrast-switcher
  .contrast-image
  svg,
.contrast-on .header .header-inner .main-navigation ul.menu li a:focus {
  color: #000040 !important;
}
.contrast-on.single-careers .header .header-inner .main-logo,
.contrast-on.single-news .header .header-inner .main-logo {
  display: block !important;
}
.contrast-on.single-careers .header .header-inner .accessblity-logo,
.contrast-on.single-news .header .header-inner .accessblity-logo {
  display: none !important;
}
.contrast-on.single-careers.header-fixed .header .header-inner .main-logo,
.contrast-on.single-news.header-fixed .header .header-inner .main-logo {
  display: none !important;
}
.contrast-on.single-careers.header-fixed
  .header
  .header-inner
  .accessblity-logo,
.contrast-on.single-news.header-fixed .header .header-inner .accessblity-logo {
  display: block !important;
}
.contrast-on.single-careers
  .header
  .header-top
  .contrast
  .contrast-switcher
  .contrast-image
  svg,
.contrast-on.single-careers
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .increase,
.contrast-on.single-careers
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .increasec
  span,
.contrast-on.single-careers
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .reset,
.contrast-on.single-careers
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .decrease,
.contrast-on.single-careers
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .decrease
  span,
.contrast-on.single-news
  .header
  .header-top
  .contrast
  .contrast-switcher
  .contrast-image
  svg,
.contrast-on.single-news
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .increase,
.contrast-on.single-news
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .increase
  span,
.contrast-on.single-news
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .reset,
.contrast-on.single-news
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .decrease,
.contrast-on.single-news
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .decrease
  span,
.contrast-on.single-careers .header .header-inner .main-navigation ul.menu li a,
.contrast-on.single-careers
  .header
  .header-inner
  .main-navigation
  ul.menu
  li
  a:hover,
.contrast-on.single-careers
  .header
  .header-inner
  .main-navigation
  ul.menu
  li
  a:focus,
.contrast-on.single-news .header .header-inner .main-navigation ul.menu li a,
.contrast-on.single-news
  .header
  .header-inner
  .main-navigation
  ul.menu
  li
  a:hover,
.contrast-on.single-news
  .header
  .header-inner
  .main-navigation
  ul.menu
  li
  a:focus {
  color: #fff !important;
}
.contrast-on.single-careers.header-fixed
  .header
  .header-top
  .contrast
  .contrast-switcher
  .contrast-image
  svg,
.contrast-on.single-careers.header-fixed
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .increase,
.contrast-on.single-careers.header-fixed
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .increasec
  span,
.contrast-on.single-careers.header-fixed
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .reset,
.contrast-on.single-careers.header-fixed
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .decrease,
.contrast-on.single-careers.header-fixed
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .decrease
  span,
.contrast-on.single-news.header-fixed
  .header
  .header-top
  .contrast
  .contrast-switcher
  .contrast-image
  svg,
.contrast-on.single-news.header-fixed
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .increase,
.contrast-on.single-news.header-fixed
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .increasec
  span,
.contrast-on.single-news.header-fixed
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .reset,
.contrast-on.single-news.header-fixed
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .decrease,
.contrast-on.single-news.header-fixed
  .header
  .header-top
  .accessblity-module
  .front-resizer
  .decrease
  span,
.contrast-on.single-careers.header-fixed
  .header
  .header-inner
  .main-navigation
  ul.menu
  li
  a,
.contrast-on.single-careers.header-fixed
  .header
  .header-inner
  .main-navigation
  ul.menu
  li
  a:hover,
.contrast-on.single-careers.header-fixed
  .header
  .header-inner
  .main-navigation
  ul.menu
  li
  a:focus,
.contrast-on.single-news.header-fixed
  .header
  .header-inner
  .main-navigation
  ul.menu
  li
  a,
.contrast-on.single-news.header-fixed
  .header
  .header-inner
  .main-navigation
  ul.menu
  li
  a:hover,
.contrast-on.single-news.header-fixed
  .header
  .header-inner
  .main-navigation
  ul.menu
  li
  a:focus {
  color: #000040 !important;
}
.contrast-on .news-banner-inner .breadcrum-outer ol li,
.contrast-on .news-banner-inner .breadcrum-outer ol li span,
.contrast-on .news-banner-inner .breadcrum-outer ol li a,
.contrast-on .news-banner-inner .breadcrum-outer ol li a:hover,
.contrast-on .news-banner-inner .breadcrum-outer ol li a:focus,
.contrast-on .news-banner-inner .breadcrum-outer ol li span:hover,
.contrast-on .carrers-banner-inner .breadcrum-outer ol li,
.contrast-on .carrers-banner-inner .breadcrum-outer ol li span,
.contrast-on .carrers-banner-inner .breadcrum-outer ol li a,
.contrast-on .carrers-banner-inner .breadcrum-outer ol li a:hover,
.contrast-on .carrers-banner-inner .breadcrum-outer ol li a:focus,
.contrast-on .carrers-banner-inner .breadcrum-outer ol li span:hover,
.contrast-on .carrers-banner-inner .breadcrum-outer ol li span:focus,
.contrast-on .news-banner-inner .breadcrum-outer ol li span:focus {
  color: #fff !important;
}
.contrast-on .page-wrapper {
  background-color: transparent;
  background-image: none;
}
.contrast-on h1,
.contrast-on h2,
.contrast-on h3,
.contrast-on h4,
.contrast-on h5,
.contrast-on h6,
.contrast-on p,
.contrast-on a,
.contrast-on span {
  color: #000 !important;
}
.contrast-on h2:before {
  color: rgba(0, 0, 0, 0.3) !important;
}
.contrast-on h2:after {
  background-color: #000 !important;
}
.contrast-on h3:before {
  color: rgba(0, 0, 0, 0.5) !important;
}
.contrast-on .contact-page,
.contrast-on .bottom-form-section,
.contrast-on .new-page-outer,
.contrast-on .page-content-wrapper,
.contrast-on .news-overview-content .highlited-news-wrapper {
  background-image: none;
}
.contrast-on .bottom-form-section .form-wrap .title-wrapp p::after,
.contrast-on .carrers-banner-inner .title-wrapper h1::after,
.contrast-on .joinus-content .joinus-main .joinus-inner h1::after,
.contrast-on .news-overview-content .highlited-news-wrapper .title h1::after,
.contrast-on
  .news-overview-content
  .news-items-wrapper
  .news-items
  .news-item
  .news-item-inner
  h3::after,
.contrast-on
  .homepage-content-wrapper
  .news-section
  .news-items
  .news-item
  .news-item-inner
  h3::after,
.contrast-on
  .homepage-content-wrapper
  .our-values-section
  .our-value-items
  .our-value-item
  .our-value-inner
  .text
  h3::after {
  background-color: #000 !important;
}
.contrast-on .banner-section .button-outer a {
  display: inline-block;
  padding: 14px 30px;
  font-size: 16px;
  font-family: "Canaro-Book" sans-serif;
  border: 2px solid #01caf3;
  background-color: transparent;
  -ms-border-radius: 30px;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  text-decoration: none;
  line-height: 1.4;
  color: #fff;
}
.contrast-on .banner-section .button-outer a:hover,
.contrast-on .banner-section .button-outer a:focus {
  background-color: #01caf3;
  color: #fff;
}
.contrast-on
  .homepage-content-wrapper
  .industry-solution-section
  .swiper-content
  .image-text
  .right-text
  h3::after {
  background-color: #000 !important;
}
.contrast-on
  .homepage-content-wrapper
  .industry-solution-section
  .swiper-content
  .image-text
  .right-text
  .button2
  a,
.contrast-on .carrer-content .bottom-section .button a,
.contrast-on .homepage-content-wrapper .news-section .bottom-link .button2 a,
.contrast-on
  .homepage-content-wrapper
  .join-us-section
  .round-image-text
  .right-text
  .button2
  a,
.contrast-on .homepage-content-wrapper .contact-us-section .button2 a,
.contrast-on
  .homepage-content-wrapper
  .industry-solution-section
  .title-link
  .button2
  a {
  background-color: transparent;
}
.contrast-on
  .carrer-content
  .overview-section
  .carrer-overview
  .carrer-overview-inner
  .carrer-item
  .cat1,
.contrast-on
  .news-banner-inner
  .add-to-any-wrapper
  .date-categoery-wrap
  .date::before,
.contrast-on .news-banner-inner .add-to-any-wrapper .date-categoery-wrap .date,
.contrast-on
  .news-banner-inner
  .add-to-any-wrapper
  .date-categoery-wrap
  .categoery,
.contrast-on .news-table table tr td,
.contrast-on
  .news-overview-content
  .news-items-wrapper
  .news-items
  .news-item
  .news-item-inner
  .date,
.contrast-on
  .news-overview-content
  .news-items-wrapper
  .news-items
  .news-item
  .news-item-inner
  .category,
.contrast-on
  .news-overview-content
  .bottom-block
  .title-text-link
  .button-wrapper
  .button-upper-text,
.contrast-on
  .news-overview-content
  .highlited-news-wrapper
  .highlited-news
  .highlited-item
  .right-text
  .dtae-categoery
  .category,
.contrast-on
  .homepage-content-wrapper
  .news-section
  .news-items
  .news-item
  .news-item-inner
  .category,
.contrast-on
  .homepage-content-wrapper
  .key-figures-section
  .key-figures-inner
  .figures
  .figure
  .figure-wrap
  .text {
  color: #000;
}
.contrast-on
  .homepage-content-wrapper
  .industry-solution-section
  .swiper-content
  .swiper-pagination
  .swiper-pagination-bullet,
.contrast-on
  .homepage-content-wrapper
  .anochors-home-scroll-wrap
  .home-anchors
  .anchor-item
  span {
  background-color: #000 !important;
}
.contrast-on
  .carrer-content
  .our-culture
  .round-image-text
  .round-image
  .round-image-inner::before,
.contrast-on .carrer-content .overview-section::after,
.contrast-on .page-content-wrapper:before,
.contrast-on .page-content-wrapper:after,
.contrast-on .news-overview-content .news-items-wrapper::before,
.contrast-on .news-overview-content .news-items-wrapper::before,
.contrast-on .homepage-content-wrapper::before,
.contrast-on .carrers-card-section::after,
.contrast-on .homepage-content-wrapper::after,
.contrast-on .news-overview-content .news-items-wrapper::after,
.contrast-on .homepage-content-wrapper .our-values-section::before,
.contrast-on .homepage-content-wrapper .our-values-section::after,
.contrast-on .homepage-content-wrapper .key-figures-section::after,
.contrast-on .homepage-content-wrapper .industry-solution-section::before,
.contrast-on .homepage-content-wrapper .news-section::before,
.contrast-on
  .homepage-content-wrapper
  .join-us-section
  .round-image-text
  .round-image
  .round-image-inner::before,
.contrast-on
  .homepage-content-wrapper
  .banner-section
  .banner-inner
  .right-image-video
  .right-image-inner::before {
  display: none;
}
.contrast-on .footer-accessblity-logo,
.contrast-on .accessblity-logo {
  display: block !important;
}
.contrast-on .footer-main-logo,
.contrast-on .main-logo {
  display: none !important;
}
.contrast-on
  .homepage-content-wrapper
  .our-values-section
  .our-value-items
  .our-value-item
  .our-value-inner {
  background-color: #f0f0f0 !important;
  background-image: none !important;
}
.contrast-on .carrers-card-section .card-inner,
.contrast-on
  .carrer-content
  .our-team
  .team-outer
  .member-wrap
  .member-description,
.contrast-on
  .carrer-content
  .overview-section
  .carrer-overview
  .carrer-filter
  .work-type-filter
  .filter-type,
.contrast-on
  .carrer-content
  .overview-section
  .carrer-overview
  .carrer-filter
  .team-filter
  .filter-type,
.contrast-on
  .carrer-content
  .overview-section
  .carrer-overview
  .carrer-overview-inner
  .carrer-item,
.contrast-on
  .news-overview-content
  .highlited-news-wrapper
  .highlited-news
  .highlited-item,
.contrast-on
  .news-overview-content
  .news-items-wrapper
  .news-items
  .news-item
  .news-item-inner,
.contrast-on
  .homepage-content-wrapper
  .news-section
  .news-items
  .news-item
  .news-item-inner,
.contrast-on
  .homepage-content-wrapper
  .industry-solution-section
  .swiper-content
  .image-text,
.contrast-on
  .homepage-content-wrapper
  .key-figures-section
  .key-figures-inner
  .figures
  .figure
  .figure-wrap {
  background-color: #f0f0f0 !important;
}
.contrast-on .news-overview-content .bottom-block .title-text-link {
  background-color: #f0f0f0 !important;
  background-image: none !important;
}
.contrast-on
  .news-overview-content
  .bottom-block
  .title-text-link
  .button-wrapper
  .button2
  a {
  background-color: transparent;
}
.contrast-on
  .news-overview-content
  .news-items-wrapper
  .categoery-filter
  .flexbox
  li:hover
  a,
.contrast-on
  .news-overview-content
  .news-items-wrapper
  .categoery-filter
  .flexbox
  li:focus
  a,
.contrast-on
  .news-overview-content
  .news-items-wrapper
  .categoery-filter
  .flexbox
  li.active
  a {
  border: 2px solid #01caf3;
  background-color: #f0f0f0;
}
.contrast-on .page-wrapper .container-fluid {
  background-color: #fff;
}
.contrast-on .wprm-wrapper .hamburger div {
  background-color: #000;
}
.contrast-on .wprm-wrapper .nav-wrapper {
  background-color: #ffffff;
}
.contrast-on
  .news-banner-inner
  .add-to-any-wrapper
  .date-categoery-wrap
  .categoery,
.contrast-on .news-banner-inner .add-to-any-wrapper .date-categoery-wrap .date,
.contrast-on
  .news-banner-inner
  .add-to-any-wrapper
  .date-categoery-wrap
  .date::before,
.contrast-on .news-banner-inner .add-to-any-wrapper .ata-innner > span,
.contrast-on .carrers-banner-inner .title-wrapper h1,
.contrast-on
  .contrast-on
  .news-banner-inner
  .add-to-any-wrapper
  .ata-innner
  .addtoany_list
  a
  .a2a_svg
  path,
.contrast-on .news-banner-inner .title-wrapper h1 {
  color: #fff !important;
}
.contrast-on .carrers-banner-inner .title-wrapper h1::after,
.contrast-on .news-banner-inner .title-wrapper h1::after {
  background-color: #fff !important;
}

.contrast-on {
  color: #000 !important;
  background-color: #fff !important;
}

.contrast-on.header-fixed .header {
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff !important;
}
@media screen and (min-width: 1600px) {
  .breadcrum-outer .container,
  .header .container {
    max-width: 1520px;
  }
}
@media screen and (min-width: 1920px) {
  .breadcrum-outer .container,
  .header .container {
    max-width: 1820px;
  }
}
@media screen and (min-width: 1600px) {
  .container {
    max-width: 1300px;
  }
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}
