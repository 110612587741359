 .key-figures-section {
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative
}
@media screen and (min-width: 768px) {
     .key-figures-section:after {
        position: relative;
        border-width: 260px 260px 0px 0px;
        border-style: solid;
        content: "";
        display: block;
        border-color: rgba(0, 6, 74, 0.5);
        position: absolute;
        height: calc(100% + 50px);
        width: calc(50vw + 110px);
        border-radius: 0px 340px 0px 0px;
        right: calc(50% - 100px);
        bottom: -404px;
        z-index: 2
    }
}
@media screen and (min-width: 768px) {
     .key-figures-section {
        padding-top: 75px;
        padding-bottom: 75px
    }
}
@media screen and (min-width: 992px) {
     .key-figures-section {
        padding-top: 95px;
        padding-bottom: 95px
    }
}
@media screen and (min-width: 1200px) {
     .key-figures-section {
        padding-top: 110px;
        padding-bottom: 110px
    }
}
@media screen and (min-width: 1920px) {
     .key-figures-section:after {
        border-color: #00064a
    }
}
@media screen and (max-width: 767px) {
     .key-figures-section .key-figures-inner {
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        -ms-flex-direction: column-reverse;
        -webkit-flex-direction: column-reverse;
        flex-direction: column-reverse
    }
}
@media screen and (min-width: 768px) {
     .key-figures-section .key-figures-inner {
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        position: relative;
        z-index: 3
    }
}
 .key-figures-section .key-figures-inner .figures {
    width: 100%;
    text-align: center
}
@media screen and (max-width: 991px) {
     .key-figures-section .key-figures-inner .figures {
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap
    }
}
@media screen and (min-width: 768px) {
     .key-figures-section .key-figures-inner .figures {
        width: 65%;
        padding-right: 20px;
        position: relative;
        margin: 0 -10px
    }
}
@media screen and (min-width: 992px) {
     .key-figures-section .key-figures-inner .figures {
        padding-right: 30px
    }
}
@media screen and (min-width: 1200px) {
     .key-figures-section .key-figures-inner .figures {
        padding-right: 35px
    }
}
@media screen and (min-width: 1600px) {
     .key-figures-section .key-figures-inner .figures {
        padding-right: 45px
    }
}
@media screen and (min-width: 1900px) {
     .key-figures-section .key-figures-inner .figures {
        padding-right: 60px
    }
}
@media screen and (max-width: 991px) {
     .key-figures-section .key-figures-inner .figures .figure {
        width: 50%;
        padding: 0 10px;
        margin-bottom: 20px
    }
}
@media screen and (min-width: 992px) {
     .key-figures-section .key-figures-inner .figures .figure {
        padding: 0 10px
    }
}
 .key-figures-section .key-figures-inner .figures .figure .figure-wrap {
    background-color: #006;
    padding: 40px 20px;
    -ms-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 67px 38px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 67px 38px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 67px 38px 0px rgba(0, 0, 0, 0.4);
    text-align: left;
    margin-bottom: 25px
}
@media screen and (max-width: 767px) {
     .key-figures-section .key-figures-inner .figures .figure .figure-wrap {
        padding: 40px 20px 0px;
        height: 100%
    }
}
 .key-figures-section .key-figures-inner .figures .figure .figure-wrap .count-wrap span {
    font-family: 'Canaro-SemiBold';
    font-size: 90px;
    color: #fff;
    line-height: 1
}
@media screen and (max-width: 767px) {
     .key-figures-section .key-figures-inner .figures .figure .figure-wrap .count-wrap span {
        font-size: 30px
    }
}
 .key-figures-section .key-figures-inner .figures .figure .figure-wrap .text {
    font-size: 18px;
    color: #01CAF3;
    font-family: 'Canaro-Light'
}
@media screen and (max-width: 767px) {
     .key-figures-section .key-figures-inner .figures .figure .figure-wrap .text {
        font-size: 15px
    }
}
@media screen and (min-width: 992px) {
     .key-figures-section .key-figures-inner .figures .figure.item0 {
        width: 45%
    }
     .key-figures-section .key-figures-inner .figures .figure.item0 .figure-wrap .count-wrap span {
        font-size: 115px
    }
     .key-figures-section .key-figures-inner .figures .figure.item1 {
        width: 30%
    }
     .key-figures-section .key-figures-inner .figures .figure.item1 .figure-wrap .count-wrap span {
        font-size: 50px
    }
     .key-figures-section .key-figures-inner .figures .figure.item2 {
        width: 30%
    }
     .key-figures-section .key-figures-inner .figures .figure.item2 .figure-wrap .count-wrap span {
        font-size: 90px
    }
     .key-figures-section .key-figures-inner .figures .figure.item3 {
        width: 30%
    }
     .key-figures-section .key-figures-inner .figures .figure.item3 .figure-wrap .count-wrap span {
        font-size: 50px
    }
     .key-figures-section .key-figures-inner .figures .figure.item4 {
        width: 25%
    }
     .key-figures-section .key-figures-inner .figures .figure.item4 .figure-wrap .count-wrap span {
        font-size: 60px
    }
     .key-figures-section .key-figures-inner .figures .figure.item5 {
        width: 30%
    }
     .key-figures-section .key-figures-inner .figures .figure.item5 .figure-wrap .count-wrap span {
        font-size: 50px
    }
     .key-figures-section .key-figures-inner .figures .figure.item6 {
        width: 32%
    }
     .key-figures-section .key-figures-inner .figures .figure.item6 .figure-wrap .count-wrap span {
        font-size: 90px
    }
}
 .key-figures-section .key-figures-inner .key-text {
    width: 100%
}
@media screen and (max-width: 767px) {
     .key-figures-section .key-figures-inner .key-text {
        padding-bottom: 30px;
        text-align: center
    }
}
@media screen and (min-width: 768px) {
     .key-figures-section .key-figures-inner .key-text {
        width: 35%;
        padding-left: 20px;
        padding-top: 75px
    }
}
@media screen and (min-width: 992px) {
     .key-figures-section .key-figures-inner .key-text {
        padding-left: 30px
    }
}
@media screen and (min-width: 1200px) {
     .key-figures-section .key-figures-inner .key-text {
        padding-left: 35px
    }
}
@media screen and (min-width: 1600px) {
     .key-figures-section .key-figures-inner .key-text {
        padding-left: 45px
    }
}
@media screen and (min-width: 1900px) {
     .key-figures-section .key-figures-inner .key-text {
        padding-left: 60px
    }
}
 .key-figures-section .key-figures-inner .key-text h2 {
    margin: 0px;
    font-size: 26px;
    font-family: 'Canaro-Bold';
    color: #fff;
    position: relative;
    line-height: 1.1;
    padding-bottom: 25px;
    z-index: 1
}
 .key-figures-section .key-figures-inner .key-text h2:before {
    content: "03";
    display: block;
    color: #0a1650;
    position: absolute;
    font-family: 'Canaro-Bold';
    z-index: -1;
    left: 0px;
    top: -65px;
    font-size: 120px
}
@media screen and (max-width: 767px) {
     .key-figures-section .key-figures-inner .key-text h2:before {
        right: 0px;
        margin: auto
    }
}
@media screen and (min-width: 768px) {
     .key-figures-section .key-figures-inner .key-text h2:before {
        font-size: 150px;
        top: -85px;
        left: -25px
    }
}
@media screen and (min-width: 992px) {
     .key-figures-section .key-figures-inner .key-text h2:before {
        font-size: 170px;
        top: -85px;
        left: -35px
    }
}
@media screen and (min-width: 1200px) {
     .key-figures-section .key-figures-inner .key-text h2:before {
        font-size: 190px;
        top: -85px;
        left: -95px
    }
}
 .key-figures-section .key-figures-inner .key-text h2:after {
    content: "";
    display: block;
    width: 75px;
    background-color: #01CAF3;
    height: 5px;
    margin-top: 15px
}
@media screen and (max-width: 767px) {
     .key-figures-section .key-figures-inner .key-text h2:after {
        margin: auto;
        margin-top: 15px
    }
}
@media screen and (min-width: 768px) {
     .key-figures-section .key-figures-inner .key-text h2 {
        font-size: 34px
    }
}
@media screen and (min-width: 992px) {
     .key-figures-section .key-figures-inner .key-text h2 {
        font-size: 42px
    }
}
@media screen and (min-width: 1200px) {
     .key-figures-section .key-figures-inner .key-text h2 {
        font-size: 51px
    }
}
 .key-figures-section .key-figures-inner .key-text p {
    font-size: 16px;
    margin-bottom: 0px
}