.sidebar-menu {
    position: absolute;
    z-index: 9
}

@media screen and (max-width: 767px) {
    .sidebar-menu {
        display: none
    }
}
@media screen and (min-width: 768px) {
    .sidebar-menu {
        right: 15px;
        top: 120px
    }
}
@media screen and (min-width: 992px) {
    .sidebar-menu {
        right: 25px;
        top: 150px
    }
}
@media screen and (min-width: 1200px) {
    .sidebar-menu {
        top: 250px
    }
}
@media screen and (min-width: 1300px) {
    .sidebar-menu {
        right: 50px
    }
}
.sidebar-menu .social-links {
    display: block
}
.sidebar-menu .social-links a {
    display: block;
    color: #fff;
    text-decoration: none;
    font-size: 24px;
    margin-bottom: 15px
}
.sidebar-menu .social-links a:hover,
.sidebar-menu .social-links a:focus {
    color: #01CAF3
}