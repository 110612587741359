 .what-wedo-section {
    margin-top: 200px;
    padding-top: 40px;
    padding-bottom: 40px;
    position: relative;
    z-index: 2
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .what-wedo-section {
        padding-top: 100px;
        padding-bottom: 75px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .what-wedo-section {
        padding-top: 120px;
        padding-bottom: 95px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .what-wedo-section {
        padding-top: 130px;
        padding-bottom: 110px
    }
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .what-wedo-section .image-text {
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between
    }
}
.homepage-content-wrapper .what-wedo-section .image-text .left-image {
    width: 100%;
    text-align: center
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .what-wedo-section .image-text .left-image {
        width: 50%;
        padding-right: 20px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .what-wedo-section .image-text .left-image {
        padding-right: 30px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .what-wedo-section .image-text .left-image {
        padding-right: 35px
    }
}
@media screen and (min-width: 1600px) {
    .homepage-content-wrapper .what-wedo-section .image-text .left-image {
        padding-right: 45px
    }
}
@media screen and (min-width: 1900px) {
    .homepage-content-wrapper .what-wedo-section .image-text .left-image {
        padding-right: 60px
    }
}
.homepage-content-wrapper .what-wedo-section .image-text .left-image img {
    margin: auto
}
.homepage-content-wrapper .what-wedo-section .image-text .right-text {
    width: 100%
}
@media screen and (max-width: 767px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text {
        padding-top: 120px;
        text-align: center
    }
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text {
        width: 50%;
        padding-left: 20px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text {
        padding-left: 30px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text {
        padding-left: 35px
    }
}
@media screen and (min-width: 1600px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text {
        padding-left: 45px
    }
}
@media screen and (min-width: 1900px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text {
        padding-left: 60px
    }
}
.homepage-content-wrapper .what-wedo-section .image-text .right-text h2 {
    margin: 0px;
    font-size: 26px;
    font-family: 'Canaro-Bold';
    color: #fff;
    position: relative;
    line-height: 1.1;
    padding-bottom: 25px;
    z-index: 1
}
.homepage-content-wrapper .what-wedo-section .image-text .right-text h2:before {
    content: "01";
    display: block;
    color: #0a1650;
    position: absolute;
    font-family: 'Canaro-Bold';
    z-index: -1;
    left: 0px;
    top: -65px;
    font-size: 120px
}
@media screen and (max-width: 767px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text h2:before {
        right: 0px;
        margin: auto
    }
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text h2:before {
        font-size: 150px;
        top: -85px;
        left: -45px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text h2:before {
        font-size: 170px;
        top: -85px;
        left: -65px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text h2:before {
        font-size: 190px;
        top: -85px;
        left: -95px
    }
}
.homepage-content-wrapper .what-wedo-section .image-text .right-text h2:after {
    content: "";
    display: block;
    width: 75px;
    background-color: #01CAF3;
    height: 5px;
    margin-top: 15px
}
@media screen and (max-width: 767px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text h2:after {
        margin: auto;
        margin-top: 15px
    }
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text h2 {
        font-size: 34px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text h2 {
        font-size: 42px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text h2 {
        font-size: 51px
    }
}
.homepage-content-wrapper .what-wedo-section .image-text .right-text p {
    font-size: 16px;
    margin-bottom: 0px
}
.homepage-content-wrapper .what-wedo-section .image-text .right-text .button2 {
    margin-top: 20px
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .what-wedo-section .image-text .right-text .button2 {
        margin-top: 45px
    }
}