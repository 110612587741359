.modal{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 999;
    display: none;
    padding: 20px;
    background: rgba(0, 0, 0, .5);
}
.modalContent{
    padding: 20px;
    background: rgb(8, 38, 83);
    background-color: rgba(4, 43, 73, 0.89);
	border-radius:3px;
    border-radius: 20px;
    min-width: 300px;
}
.modal.active{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}