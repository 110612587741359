.homepage-content-wrapper .industry-solution-section {
    padding-top: 40px;
    padding-bottom: 80px;
    position: relative
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section:before {
        content: "";
        position: absolute;
        width: 1502px;
        height: 1155px;
        right: 0px;
        bottom: 50px;
        background: #000040;
        background-repeat: no-repeat;
        background-image: url("../../../public/assets/images/lueur3.png");
        background-position: center right;
        background-blend-mode: screen;
        background-size: contain;
        z-index: 1
    }
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section {
        padding-top: 75px;
        padding-bottom: 120px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .industry-solution-section {
        padding-top: 95px;
        padding-bottom: 150px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .industry-solution-section {
        padding-top: 110px;
        padding-bottom: 180px
    }
}
.homepage-content-wrapper .industry-solution-section .title-link {
    position: relative;
    z-index: 2
}
@media screen and (max-width: 767px) {
    .homepage-content-wrapper .industry-solution-section .title-link {
        text-align: center
    }
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section .title-link {
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        align-items: center;
        -webkit-align-items: center;
        -ms-align-items: center;
        -o-align-items: center;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between
    }
}
.homepage-content-wrapper .industry-solution-section .title-link h2 {
    margin: 0px;
    font-size: 26px;
    font-family: 'Canaro-Bold';
    color: #fff;
    position: relative;
    line-height: 1.1;
    z-index: 1
}
.homepage-content-wrapper .industry-solution-section .title-link h2:before {
    content: "04";
    display: block;
    color: #0a1650;
    position: absolute;
    font-family: 'Canaro-Bold';
    z-index: -1;
    left: 0px;
    top: -65px;
    font-size: 120px
}
@media screen and (max-width: 767px) {
    .homepage-content-wrapper .industry-solution-section .title-link h2:before {
        right: 0px;
        margin: auto
    }
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section .title-link h2:before {
        font-size: 150px;
        top: -85px;
        left: -25px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .industry-solution-section .title-link h2:before {
        font-size: 170px;
        top: -85px;
        left: -35px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .industry-solution-section .title-link h2:before {
        font-size: 190px;
        top: -85px;
        left: -95px
    }
}
.homepage-content-wrapper .industry-solution-section .title-link h2:after {
    content: "";
    display: block;
    width: 75px;
    background-color: #01CAF3;
    height: 5px;
    margin-top: 15px
}
@media screen and (max-width: 767px) {
    .homepage-content-wrapper .industry-solution-section .title-link h2:after {
        margin: auto;
        margin-top: 15px
    }
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section .title-link h2 {
        font-size: 34px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .industry-solution-section .title-link h2 {
        font-size: 42px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .industry-solution-section .title-link h2 {
        font-size: 51px
    }
}
@media screen and (max-width: 767px) {
    .homepage-content-wrapper .industry-solution-section .title-link .button2 {
        padding-top: 20px
    }
}
.homepage-content-wrapper .industry-solution-section .title-link .button2 a {
    background-color: #000040
}
.homepage-content-wrapper .industry-solution-section .title-link .button2 a:hover,
.homepage-content-wrapper .industry-solution-section .title-link .button2 a:focus {
    background-color: #01CAF3;
    color: #fff
}
.homepage-content-wrapper .industry-solution-section .swiper-content {
    padding-top: 30px;
    position: relative;
    z-index: 2
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content {
        padding-top: 45px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content {
        padding-top: 75px
    }
}
.homepage-content-wrapper .industry-solution-section .swiper-content .image-text {
    background-color: #000040
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .image-text {
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between
    }
}
.homepage-content-wrapper .industry-solution-section .swiper-content .image-text .left-text {
    width: 100%
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .image-text .left-text {
        width: 50%
    }
}
.homepage-content-wrapper .industry-solution-section .swiper-content .image-text .left-text img {
    width: 100%;
    height: 100%;
    object-fit: cover
}
.homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text {
    width: 100%;
    padding: 40px 15px
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text {
        width: 50%;
        padding: 40px 20px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text {
        padding: 50px 30px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text {
        padding: 50px 40px
    }
}
@media screen and (min-width: 1600px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text {
        padding: 50px 50px
    }
}
@media screen and (min-width: 1900px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text {
        padding: 50px 60px
    }
}
.homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text h3 {
    margin: 0px;
    font-size: 21px;
    font-family: 'Canaro-SemiBold';
    color: #fff;
    position: relative;
    line-height: 1.1;
    padding-bottom: 20px;
    z-index: 1
}
.homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text h3:after {
    content: "";
    display: block;
    width: 75px;
    background-color: #01CAF3;
    height: 5px;
    margin-top: 15px
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text h3 {
        font-size: 22px;
        padding-bottom: 25px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text h3 {
        font-size: 28px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text h3 {
        font-size: 32px
    }
}
.homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text p {
    font-size: 16px;
    margin-bottom: 0px
}
.homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text .button2 {
    padding-top: 20px
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text .button2 {
        padding-top: 35px
    }
}
.homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text .button2 a {
    background-color: #000040
}
.homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text .button2 a:hover,
.homepage-content-wrapper .industry-solution-section .swiper-content .image-text .right-text .button2 a:focus {
    background-color: #01CAF3;
    color: #fff
}
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer {
    position: absolute;
    width: 100%;
    display: inline-block;
    height: auto;
    margin: auto;
    margin-top: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9
}
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-next,
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-prev {
    font-size: 0px;
    width: 25px;
    height: 25px;
    background-color: transparent;
    line-height: 1.8;
    position: absolute;
    cursor: pointer;
    padding: 0px;
    border: none;
    transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out
}
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-next:after,
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-prev:after {
    display: none
}
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-next:before,
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-prev:before {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    text-align: center;
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out
}
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-prev {
    left: -15px
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-prev {
        left: 0px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-prev {
        left: -40px
    }
}
@media screen and (min-width: 1300px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-prev {
        left: -80px
    }
}
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-prev:before {
    background-image: url("../../../public/assets/images/swiper-right.png")
}
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-next {
    right: -15px
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-next {
        right: 0px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-next {
        right: -40px
    }
}
@media screen and (min-width: 1300px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-next {
        right: -80px
    }
}
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-next:before {
    background-image: url("../../../public/assets/images/swiper-left.png")
}
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination-outer .swiper-button-disabled {
    opacity: 1;
    pointer-events: none;
    cursor: pointer
}
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination {
    left: 0px;
    right: 0px;
    margin: auto;
    bottom: -30px
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination {
        bottom: -60px
    }
}
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    background-color: #fff;
    margin: 0 10px;
    position: relative;
    opacity: 1;
    cursor: pointer
}
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination .swiper-pagination-bullet:before {
    width: 22px;
    height: 22px;
    border: 1px solid #01CAF3;
    position: absolute;
    top: -6.8px;
    left: -7px;
    display: block;
    content: "";
    opacity: 0;
    -ms-border-radius: 11px;
    -webkit-border-radius: 11px;
    border-radius: 11px
}
.homepage-content-wrapper .industry-solution-section .swiper-content .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active:before {
    opacity: 1
}
.carrer-content .our-team .team-outer .swiper-wrapper .swiper-slide:nth-child(3n+1) .member-wrap:before {
    background-image: url("../../../public/assets/images/bloc-fond1.png")
}
.carrer-content .our-team .team-outer .swiper-wrapper .swiper-slide:nth-child(3n+2) .member-wrap:before {
    background-image: url("../../../public/assets/images/bloc-fond2.png")
}
.carrer-content .our-team .team-outer .swiper-wrapper .swiper-slide:nth-child(3n+3) .member-wrap:before {
    background-image: url("../../../public/assets/images/bloc-fond3.png")
}
.swiper-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1
}
.swiper-container-vertical > .swiper-wrapper {
    flex-direction: column
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box
}
.carrer-content .our-team .team-outer .swiper-button-outer {
    position: absolute;
    width: 100%;
    display: inline-block;
    height: auto;
    margin: auto;
    margin-top: 0px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 9999
}
.carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-next,
.carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-prev {
    font-size: 0px;
    width: 25px;
    height: 25px;
    background-color: transparent;
    line-height: 1.8;
    position: absolute;
    cursor: pointer;
    padding: 0px;
    border: none;
    transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out
}
.swiper-slide-zoomed {
    cursor: move
}
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
    transition-timing-function: ease-out
}
.swiper-container-fade .swiper-slide {
    pointer-events: none;
    transition-property: opacity
}
.swiper-container-fade .swiper-slide .swiper-slide {
    pointer-events: none
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}

.swiper-container-flip {
    overflow: visible
}
.swiper-container-flip .swiper-slide {
    pointer-events: none;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 1
}
.swiper-container-flip .swiper-slide .swiper-slide {
    pointer-events: none
}
.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
    pointer-events: auto
}
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
    z-index: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}
.carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-next:after,
.carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-prev:after {
    display: none
}
.carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-next:before,
.carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-prev:before {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    text-align: center;
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out
}
.carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-prev {
    left: 0px
}
@media screen and (min-width: 768px) {
    .carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-prev {
        left: -35px
    }
}
@media screen and (min-width: 992px) {
    .carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-prev {
        left: -25px
    }
}
@media screen and (min-width: 1200px) {
    .carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-prev {
        left: -40px
    }
}
@media screen and (min-width: 1300px) {
    .carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-prev {
        left: -80px
    }
}
.carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-prev:before {
    background-image: url("../../../public/assets/images/swiper-right.png")
}
.carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-next {
    right: 0px
}
@media screen and (min-width: 768px) {
    .carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-next {
        right: -35px
    }
}
@media screen and (min-width: 992px) {
    .carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-next {
        right: -25px
    }
}
@media screen and (min-width: 1200px) {
    .carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-next {
        right: -40px
    }
}
@media screen and (min-width: 1300px) {
    .carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-next {
        right: -80px
    }
}
.carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-next:before {
    background-image: url("../../../public/assets/images/swiper-left.png")
}
.carrer-content .our-team .team-outer .swiper-button-outer .swiper-button-disabled {
    opacity: 1;
    pointer-events: none;
    cursor: pointer
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
    transform: translate3d(0px, 0, 0)
}
.swiper-container-multirow > .swiper-wrapper {
    flex-wrap: wrap
}
.swiper-container-multirow-column > .swiper-wrapper {
    flex-wrap: wrap;
    flex-direction: column
}
.swiper-container-free-mode > .swiper-wrapper {
    transition-timing-function: ease-out;
    margin: 0 auto
}
.swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform
}
.swiper-slide-invisible-blank {
    visibility: hidden
}
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
    height: auto
}
.swiper-container-autoheight .swiper-wrapper {
    align-items: flex-start;
    transition-property: transform, height
}
.swiper-container-3d {
    perspective: 1200px
}
.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
    transform-style: preserve-3d
}
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 10
}
.swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}
.swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}
.swiper-container-3d .swiper-slide-shadow-top {
    background-image: linear-gradient(to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}
.swiper-container-3d .swiper-slide-shadow-bottom {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5), rgba(0, 0, 0, 0))
}
.swiper-container-css-mode > .swiper-wrapper {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none
}
.swiper-button-next,
.swiper-button-prev {
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size)/ 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(-1 * var(--swiper-navigation-size)/ 2);
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color))
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: .35;
    cursor: auto;
    pointer-events: none
}
.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: swiper-icons;
    font-size: var(--swiper-navigation-size);
    text-transform: none !important
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: 10px;
    right: auto
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
    content: 'prev'
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
    content: 'next'
}
.swiper-button-next.swiper-button-white,
.swiper-button-prev.swiper-button-white {
    --swiper-navigation-color: #ffffff
}
.swiper-button-next.swiper-button-black,
.swiper-button-prev.swiper-button-black {
    --swiper-navigation-color: #000000
}
.swiper-button-lock {
    display: none
}
.swiper-pagination {
    position: absolute;
    text-align: center;
    transition: .3s opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10
}
.swiper-pagination.swiper-pagination-hidden {
    opacity: 0
}
.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    bottom: 10px;
    left: 0;
    width: 100%
}
.swiper-pagination-bullets-dynamic {
    overflow: hidden;
    font-size: 0
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transform: scale(.33);
    position: relative
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
    transform: scale(1)
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
    transform: scale(1)
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    transform: scale(.66)
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    transform: scale(.33)
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    transform: scale(.66)
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(.33)
}
.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 100%;
    background: #000;
    opacity: .2
}
button.swiper-pagination-bullet {
    border: none;
    margin: 0;
    padding: 0;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}
.swiper-pagination-clickable .swiper-pagination-bullet {
    cursor: pointer
}
.swiper-pagination-bullet-active {
    opacity: 1;
    background: var(--swiper-pagination-color, var(--swiper-theme-color))
}
.swiper-container-vertical > .swiper-pagination-bullets {
    right: 10px;
    top: 50%;
    transform: translate3d(0px, -50%, 0)
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 6px 0;
    display: block
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    top: 50%;
    transform: translateY(-50%);
    width: 8px
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    display: inline-block;
    transition: .2s transform, .2s top
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 4px
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: .2s transform, .2s left
}
.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
    transition: .2s transform, .2s right
}
.swiper-pagination-progressbar {
    background: rgba(0, 0, 0, .25);
    position: absolute
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: var(--swiper-pagination-color, var(--swiper-theme-color));
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: scale(0);
    transform-origin: left top
}
.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    transform-origin: right top
}
.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0
}
.swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-container-vertical > .swiper-pagination-progressbar {
    width: 4px;
    height: 100%;
    left: 0;
    top: 0
}
.swiper-pagination-white {
    --swiper-pagination-color: #ffffff
}
.swiper-pagination-black {
    --swiper-pagination-color: #000000
}
.swiper-pagination-lock {
    display: none
}
.swiper-scrollbar {
    border-radius: 10px;
    position: relative;
    background: rgba(0, 0, 0, .1)
}
.swiper-container-horizontal > .swiper-scrollbar {
    position: absolute;
    left: 1%;
    bottom: 3px;
    z-index: 50;
    height: 5px;
    width: 98%
}
.swiper-container-vertical > .swiper-scrollbar {
    position: absolute;
    right: 3px;
    top: 1%;
    z-index: 50;
    width: 5px;
    height: 98%
}
.swiper-scrollbar-drag {
    height: 100%;
    width: 100%;
    position: relative;
    background: rgba(0, 0, 0, .5);
    border-radius: 10px;
    left: 0;
    top: 0
}
.swiper-scrollbar-cursor-drag {
    cursor: move
}
.swiper-scrollbar-lock {
    display: none
}
.swiper-zoom-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center
}
.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain
}

.swiper-lazy-preloader {
    width: 42px;
    height: 42px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -21px;
    margin-top: -21px;
    z-index: 10;
    transform-origin: 50%;
    animation: swiper-preloader-spin 1s infinite linear;
    box-sizing: border-box;
    border: 4px solid var(--swiper-preloader-color, var(--swiper-theme-color));
    border-radius: 50%;
    border-top-color: transparent
}
.swiper-lazy-preloader-white {
    --swiper-preloader-color: #fff
}
.swiper-lazy-preloader-black {
    --swiper-preloader-color: #000
}
@keyframes swiper-preloader-spin {
    100% {
        transform: rotate(360deg)
    }
}
.swiper-container .swiper-notification {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    opacity: 0;
    z-index: -1000
}

.swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
    display: none
}
.swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
    scroll-snap-align: start start
}
.swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
    scroll-snap-type: x mandatory
}
.swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
    scroll-snap-type: y mandatory
}