.homepage-content-wrapper .news-section {
    padding-top: 60px;
    padding-bottom: 40px
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .news-section {
        position: relative
    }
    .homepage-content-wrapper .news-section:before {
        background-image: url("../../../public/assets/images/comtact-bottom-right.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        content: "";
        position: absolute;
        width: 1445px;
        height: 984px;
        left: 0px;
        right: 0px;
        margin: auto;
        top: 0px;
        z-index: 2
    }
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .news-section {
        padding-top: 75px;
        padding-bottom: 75px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .news-section {
        padding-top: 95px;
        padding-bottom: 95px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .news-section {
        padding-top: 110px;
        padding-bottom: 110px
    }
}
.homepage-content-wrapper .news-section .news-inner {
    position: relative;
    z-index: 3
}
.homepage-content-wrapper .news-section h2 {
    margin: 0px;
    font-size: 26px;
    font-family: 'Canaro-Bold';
    color: #fff;
    position: relative;
    line-height: 1.1;
    padding-bottom: 25px;
    z-index: 1;
    text-align: center
}
.homepage-content-wrapper .news-section h2:before {
    content: "05";
    display: block;
    color: #0a1650;
    position: absolute;
    left: 0px;
    right: 0px;
    margin: auto;
    font-family: 'Canaro-Bold';
    z-index: -1;
    top: -65px;
    font-size: 120px
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .news-section h2:before {
        font-size: 150px;
        top: -85px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .news-section h2:before {
        font-size: 170px;
        top: -85px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .news-section h2:before {
        font-size: 190px;
        top: -105px
    }
}
.homepage-content-wrapper .news-section h2:after {
    content: "";
    display: block;
    width: 75px;
    background-color: #01CAF3;
    height: 5px;
    margin: auto;
    margin-top: 15px
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .news-section h2 {
        font-size: 34px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .news-section h2 {
        font-size: 42px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .news-section h2 {
        font-size: 51px
    }
}
.homepage-content-wrapper .news-section .news-items {
    position: relative;
    z-index: 4
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .news-section .news-items {
        padding-top: 60px;
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        margin: 0 -15px
    }
}
.homepage-content-wrapper .news-section .news-items .news-item {
    width: 100%;
    margin-bottom: 30px
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .news-section .news-items .news-item {
        width: 50%;
        padding: 0 15px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .news-section .news-items .news-item {
        width: 33.3333%
    }
}
.homepage-content-wrapper .news-section .news-items .news-item .news-item-inner {
    -ms-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background-color: #000040;
    padding: 30px 15px;
    position: relative;
    height: 100%
}
@media screen and (max-width: 767px) {
    .homepage-content-wrapper .news-section .news-items .news-item .news-item-inner {
        border: 2px solid #fff
    }
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .news-section .news-items .news-item .news-item-inner {
        padding: 30px 20px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .news-section .news-items .news-item .news-item-inner {
        padding: 30px 30px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .news-section .news-items .news-item .news-item-inner {
        padding: 30px 35px
    }
}
@media screen and (min-width: 1600px) {
    .homepage-content-wrapper .news-section .news-items .news-item .news-item-inner {
        padding: 30px 40px
    }
}
.homepage-content-wrapper .news-section .news-items .news-item .news-item-inner a {
    display: inline-block
}
.homepage-content-wrapper .news-section .news-items .news-item .news-item-inner a:before {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    display: block;
    content: "";
    z-index: 2
}
.homepage-content-wrapper .news-section .news-items .news-item .news-item-inner .category {
    font-size: 16px;
    color: #01CAF3;
    margin-bottom: 20px
}
.homepage-content-wrapper .news-section .news-items .news-item .news-item-inner .date {
    font-size: 16px;
    color: #fff;
    margin-bottom: 20px
}
.homepage-content-wrapper .news-section .news-items .news-item .news-item-inner .image img {
    width: 100%
}
.homepage-content-wrapper .news-section .news-items .news-item .news-item-inner h3 {
    margin: 0px;
    font-size: 18px;
    font-family: 'Canaro-Bold';
    color: #fff;
    position: relative;
    line-height: 1.1;
    z-index: 1;
    padding-bottom: 25px
}
.homepage-content-wrapper .news-section .news-items .news-item .news-item-inner h3:after {
    content: "";
    display: block;
    width: 75px;
    background-color: #01CAF3;
    height: 5px;
    margin-top: 20px
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .news-section .news-items .news-item .news-item-inner h3 {
        font-size: 22px
    }
}
@media screen and (min-width: 992px) {
    .homepage-content-wrapper .news-section .news-items .news-item .news-item-inner h3 {
        font-size: 25px
    }
}
.homepage-content-wrapper .news-section .bottom-link {
    padding-top: 25px;
    text-align: center;
    position: relative;
    z-index: 4
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .news-section .bottom-link {
        padding-top: 50px
    }
}
.homepage-content-wrapper .news-section .bottom-link .button2 a {
    background-color: #000040
}
.homepage-content-wrapper .news-section .bottom-link .button2 a:hover,
.homepage-content-wrapper .news-section .bottom-link .button2 a:focus {
    background-color: #01CAF3;
    color: #fff
}