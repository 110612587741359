.homepage-content-wrapper .anochors-home-scroll-wrap .home-anchors {
    position: fixed;
    width: 25px;
    z-index: 999;
    top: 200px
}
@media screen and (max-width: 767px) {
    .homepage-content-wrapper .anochors-home-scroll-wrap .home-anchors {
        display: none
    }
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .anochors-home-scroll-wrap .home-anchors {
        left: 15px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .anochors-home-scroll-wrap .home-anchors {
        left: 30px
    }
}
.homepage-content-wrapper .anochors-home-scroll-wrap .home-anchors .anchor-item {
    display: block;
    transition: all 0.32s ease-in-out;
    -ms-transition: all 0.32s ease-in-out;
    -o-transition: all 0.32s ease-in-out;
    -moz-transition: all 0.32s ease-in-out;
    -webkit-transition: all 0.32s ease-in-out
}
.homepage-content-wrapper .anochors-home-scroll-wrap .home-anchors .anchor-item span {
    width: 8px;
    height: 8px;
    background-color: #fff;
    margin: 0 10px;
    position: relative;
    opacity: 1;
    cursor: pointer;
    display: inline-block;
    border-radius: 4px;
    font-size: 0
}
.homepage-content-wrapper .anochors-home-scroll-wrap .home-anchors .anchor-item span:before {
    width: 22px;
    height: 22px;
    border: 1px solid #01CAF3;
    position: absolute;
    top: -6.8px;
    left: -7px;
    display: block;
    content: "";
    opacity: 0;
    -ms-border-radius: 11px;
    -webkit-border-radius: 11px;
    border-radius: 11px
}
.homepage-content-wrapper .anochors-home-scroll-wrap .home-anchors .anchor-item.active span:before {
    opacity: 1
}
.homepage-content-wrapper .anochors-home-scroll-wrap .scroll {
    position: fixed;
    z-index: 99;
    bottom: 20px
}
@media screen and (max-width: 767px) {
    .homepage-content-wrapper .anochors-home-scroll-wrap .scroll {
        display: none
    }
}
@media screen and (min-width: 768px) {
    .homepage-content-wrapper .anochors-home-scroll-wrap .scroll {
        left: 15px
    }
}
@media screen and (min-width: 1200px) {
    .homepage-content-wrapper .anochors-home-scroll-wrap .scroll {
        left: 30px
    }
}
.homepage-content-wrapper .anochors-home-scroll-wrap .scroll .scroll-inner {
    position: relative;
    cursor: pointer
}
.homepage-content-wrapper .anochors-home-scroll-wrap .scroll .scroll-inner:before {
    background-image: url("../../../public/assets/images/scroll.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 31px;
    height: 70px;
    display: block;
    content: "";
    position: absolute;
    bottom: 0px;
    left: -1px;
    right: 0px;
    margin: auto
}
.homepage-content-wrapper .anochors-home-scroll-wrap .scroll .scroll-inner span {
    margin-bottom: 0px;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
    font-size: 14px;
    position: absolute;
    top: -120px;
    left: -12px
}