.footer {
    padding: 40px 0px;
    position: relative;
    z-index: 99
}
@media screen and (min-width: 768px) {
    .footer {
        padding: 60px 0px
    }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
    .footer .container {
        padding-right: 50px
    }
}
@media screen and (min-width: 768px) {
    .footer .footer-top {
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        -webkit-align-items: center;
        -ms-align-items: center;
        -o-align-items: center;
        border-bottom: 1px solid #01CAF3;
        padding-bottom: 35px;
        margin-bottom: 35px
    }
}
.footer .footer-top .footer-accessblity-logo {
    display: none
}
.footer .footer-top .footer-main-logo {
    display: block
}
.footer .footer-top .footer-item h3 {
    font-family: 'Canaro-Bold';
    font-size: 20px;
    margin: 0px;
    padding-bottom: 10px
}
.footer .footer-top .footer-item a {
    color: #fff;
    font-family: 'Canaro-Light';
    font-size: 17px;
    text-decoration: none
}
.footer .footer-top .footer-item a:hover,
.footer .footer-top .footer-item a:focus {
    color: #01CAF3
}
.footer .footer-top .footer-item .social-links a {
    font-size: 24px;
    margin-right: 20px
}
.back-to-top-ouuter {
    position: absolute;
    z-index: 99;
    bottom: 120px
}
@media screen and (max-width: 767px) {
    .back-to-top-ouuter {
        display: none
    }
}
@media screen and (min-width: 768px) {
    .back-to-top-ouuter {
        right: 0px;
        bottom: 90px
    }
}
@media screen and (min-width: 992px) {
    .back-to-top-ouuter {
        bottom: 80px
    }
}
@media screen and (min-width: 1200px) {
    .back-to-top-ouuter {
        bottom: 120px
    }
}
.back-to-top-ouuter .back-to-top {
    position: relative;
    cursor: pointer
}
.back-to-top-ouuter .back-to-top:before {
    background-image: url("../../../public/assets/images/backtotop.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 26px;
    height: 60px;
    display: block;
    content: "";
    position: absolute;
    top: -110px;
    left: 4px;
    right: 0px;
    margin: auto
}
.back-to-top-ouuter .back-to-top p {
    margin-bottom: 0px;
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
    font-size: 14px;
}

@media screen and (max-width: 767px) {
    .footer .footer-top .footer-item {
        margin-bottom: 30px
    }
}
.footer .footer-top .footer-item .logo-footer img {
    max-width: 280px
}
@media screen and (min-width: 768px) {
    .footer .footer-top .footer-item .logo-footer img {
        max-width: 200px
    }
}
@media screen and (min-width: 992px) {
    .footer .footer-top .footer-item .logo-footer img {
        max-width: 300px
    }
}
@media screen and (min-width: 1200px) {
    .footer .footer-top .footer-item .logo-footer img {
        max-width: 320px
    }
}
@media screen and (min-width: 768px) {
    .footer .footer-bottom-inner {
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        -webkit-align-items: center;
        -ms-align-items: center;
        -o-align-items: center
    }
}
@media screen and (max-width: 767px) {
    .footer .footer-bottom-inner .left-content {
        margin-bottom: 20px
    }
}
.footer .footer-bottom-inner .left-content p {
    font-size: 16px;
    margin-bottom: 0px;
    font-family: 'Canaro-Light'
}
.footer .footer-bottom-inner .left-content p a {
    color: #fff;
    text-decoration: none
}
.footer .footer-bottom-inner .left-content p a:hover,
.footer .footer-bottom-inner .left-content p a:focus {
    color: #01CAF3
}
.footer .footer-bottom-inner .right-links a {
    font-size: 16px;
    color: #fff;
    font-family: 'Canaro-Light';
    text-decoration: none;
    margin-left: 60px
}
@media screen and (max-width: 767px) {
    .footer .footer-bottom-inner .right-links a {
        margin-right: 30px;
        margin-left: 0px
    }
}
@media screen and (min-width: 768px) {
    .footer .footer-bottom-inner .right-links a {
        margin-left: 40px
    }
}
@media screen and (min-width: 992px) {
    .footer .footer-bottom-inner .right-links a {
        margin-left: 60px
    }
}
.footer .footer-bottom-inner .right-links a:hover,
.footer .footer-bottom-inner .right-links a:focus {
    color: #01CAF3
}