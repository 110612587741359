@media screen and (min-width: 1600px) {
    .breadcrum-outer .container,
    .header .container {
        max-width: 1520px
    }
}
@media screen and (min-width: 1920px) {
    .breadcrum-outer .container,
    .header .container {
        max-width: 1820px
    }
}
.header-fixed .scroll {
    display: none
}
.contrast-on .header .header-top .accessblity-module .front-resizer .increase,
.contrast-on .header .header-top .accessblity-module .front-resizer .increasec span,
.contrast-on .header .header-top .accessblity-module .front-resizer .reset,
.contrast-on .header .header-top .accessblity-module .front-resizer .decrease,
.contrast-on .header .header-top .accessblity-module .front-resizer .decrease span,
.contrast-on .header .header-inner .main-navigation ul.menu li a,
.contrast-on .header .header-inner .main-navigation ul.menu li a:hover,
.contrast-on .header .header-top .contrast .contrast-switcher .contrast-image svg,
.contrast-on .header .header-inner .main-navigation ul.menu li a:focus {
    color: #000040 !important
}
.contrast-on.single-careers .header .header-inner .main-logo,
.contrast-on.single-news .header .header-inner .main-logo {
    display: block !important
}
.contrast-on.single-careers .header .header-inner .accessblity-logo,
.contrast-on.single-news .header .header-inner .accessblity-logo {
    display: none !important
}
.contrast-on.single-careers.header-fixed .header .header-inner .main-logo,
.contrast-on.single-news.header-fixed .header .header-inner .main-logo {
    display: none !important
}
.contrast-on.single-careers.header-fixed .header .header-inner .accessblity-logo,
.contrast-on.single-news.header-fixed .header .header-inner .accessblity-logo {
    display: block !important
}
.contrast-on.single-careers .header .header-top .contrast .contrast-switcher .contrast-image svg,
.contrast-on.single-careers .header .header-top .accessblity-module .front-resizer .increase,
.contrast-on.single-careers .header .header-top .accessblity-module .front-resizer .increasec span,
.contrast-on.single-careers .header .header-top .accessblity-module .front-resizer .reset,
.contrast-on.single-careers .header .header-top .accessblity-module .front-resizer .decrease,
.contrast-on.single-careers .header .header-top .accessblity-module .front-resizer .decrease span,
.contrast-on.single-news .header .header-top .contrast .contrast-switcher .contrast-image svg,
.contrast-on.single-news .header .header-top .accessblity-module .front-resizer .increase,
.contrast-on.single-news .header .header-top .accessblity-module .front-resizer .increase span,
.contrast-on.single-news .header .header-top .accessblity-module .front-resizer .reset,
.contrast-on.single-news .header .header-top .accessblity-module .front-resizer .decrease,
.contrast-on.single-news .header .header-top .accessblity-module .front-resizer .decrease span,
.contrast-on.single-careers .header .header-inner .main-navigation ul.menu li a,
.contrast-on.single-careers .header .header-inner .main-navigation ul.menu li a:hover,
.contrast-on.single-careers .header .header-inner .main-navigation ul.menu li a:focus,
.contrast-on.single-news .header .header-inner .main-navigation ul.menu li a,
.contrast-on.single-news .header .header-inner .main-navigation ul.menu li a:hover,
.contrast-on.single-news .header .header-inner .main-navigation ul.menu li a:focus {
    color: #fff !important
}
.contrast-on.single-careers.header-fixed .header .header-top .contrast .contrast-switcher .contrast-image svg,
.contrast-on.single-careers.header-fixed .header .header-top .accessblity-module .front-resizer .increase,
.contrast-on.single-careers.header-fixed .header .header-top .accessblity-module .front-resizer .increasec span,
.contrast-on.single-careers.header-fixed .header .header-top .accessblity-module .front-resizer .reset,
.contrast-on.single-careers.header-fixed .header .header-top .accessblity-module .front-resizer .decrease,
.contrast-on.single-careers.header-fixed .header .header-top .accessblity-module .front-resizer .decrease span,
.contrast-on.single-news.header-fixed .header .header-top .contrast .contrast-switcher .contrast-image svg,
.contrast-on.single-news.header-fixed .header .header-top .accessblity-module .front-resizer .increase,
.contrast-on.single-news.header-fixed .header .header-top .accessblity-module .front-resizer .increasec span,
.contrast-on.single-news.header-fixed .header .header-top .accessblity-module .front-resizer .reset,
.contrast-on.single-news.header-fixed .header .header-top .accessblity-module .front-resizer .decrease,
.contrast-on.single-news.header-fixed .header .header-top .accessblity-module .front-resizer .decrease span,
.contrast-on.single-careers.header-fixed .header .header-inner .main-navigation ul.menu li a,
.contrast-on.single-careers.header-fixed .header .header-inner .main-navigation ul.menu li a:hover,
.contrast-on.single-careers.header-fixed .header .header-inner .main-navigation ul.menu li a:focus,
.contrast-on.single-news.header-fixed .header .header-inner .main-navigation ul.menu li a,
.contrast-on.single-news.header-fixed .header .header-inner .main-navigation ul.menu li a:hover,
.contrast-on.single-news.header-fixed .header .header-inner .main-navigation ul.menu li a:focus {
    color: #000040 !important
}
.header {
    padding: 20px 0px;
    position: absolute;
    width: 100%;
    top: 0px;
    z-index: 9;
    transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out
}
@media screen and (min-width: 1200px) {
    .header .container-fluid {
        padding: 0 30px
    }
}
@media screen and (min-width: 768px) {
    .header .header-inner {
        display: flex;
        display: -moz-flex;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        -o-justify-content: space-between;
        -ms-justify-content: space-between;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        align-items: center;
        -webkit-align-items: center;
        -ms-align-items: center;
        -o-align-items: center
    }
}
.header .header-inner .site-logo img {
    max-width: 220px
}
@media screen and (min-width: 768px) {
    .header .header-inner .site-logo img {
        max-width: 180px
    }
}
@media screen and (min-width: 992px) {
    .header .header-inner .site-logo img {
        max-width: 280px
    }
}
@media screen and (min-width: 1200px) {
    .header .header-inner .site-logo img {
        max-width: 320px
    }
}
.header .header-inner .main-logo {
    display: block
}
.header .header-inner .accessblity-logo {
    display: none
}
@media screen and (max-width: 767px) {
    .header .header-inner .main-navigation {
        display: none
    }
}
.header .header-inner .main-navigation ul.menu {
    padding: 0px;
    margin: 0px
}
.header .header-inner .main-navigation ul.menu li {
    list-style: none;
    display: inline-block
}
@media screen and (min-width: 768px) {
    .header .header-inner .main-navigation ul.menu li {
        padding: 0 2px
    }
}
@media screen and (min-width: 992px) {
    .header .header-inner .main-navigation ul.menu li {
        padding: 0 12px
    }
}
@media screen and (min-width: 1200px) {
    .header .header-inner .main-navigation ul.menu li {
        padding: 0 20px
    }
}
@media screen and (min-width: 1600px) {
    .header .header-inner .main-navigation ul.menu li {
        padding: 0 35px
    }
}
@media screen and (min-width: 1900px) {
    .header .header-inner .main-navigation ul.menu li {
        padding: 0 40px
    }
}
.header .header-inner .main-navigation ul.menu li a {
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    padding: 0px;
    display: inline-block
}
@media screen and (min-width: 768px) {
    .header .header-inner .main-navigation ul.menu li a {
        font-size: 13px
    }
}
@media screen and (min-width: 992px) {
    .header .header-inner .main-navigation ul.menu li a {
        font-size: 15px
    }
}
@media screen and (min-width: 1200px) {
    .header .header-inner .main-navigation ul.menu li a {
        font-size: 16px
    }
}
@media screen and (min-width: 1600px) {
    .header .header-inner .main-navigation ul.menu li a {
        font-size: 18px
    }
}
.header .header-inner .main-navigation ul.menu li a:hover,
.header .header-inner .main-navigation ul.menu li a:focus {
    color: #01CAF3
}
.header .header-inner .main-navigation ul.menu li:last-child {
    padding-right: 0px
}
.header .header-inner .main-navigation ul.menu li:last-child a {
    padding: 8px 10px;
    border: 3px solid #01CAF3;
    -ms-border-radius: 30px;
    -webkit-border-radius: 30px;
    border-radius: 30px;
    background-color: transparent
}
@media screen and (min-width: 768px) {
    .header .header-inner .main-navigation ul.menu li:last-child a {
        padding: 12px 19px
    }
}
@media screen and (min-width: 992px) {
    .header .header-inner .main-navigation ul.menu li:last-child a {
        padding: 12px 19px
    }
}
.header .header-inner .main-navigation ul.menu li:last-child a:hover,
.header .header-inner .main-navigation ul.menu li:last-child a:focus {
    background-color: #01CAF3;
    color: #fff
}
@media screen and (max-width: 767px) {
    .header .header-top {
        padding-bottom: 10px
    }
}
.header .header-top .accessblity-module {
    display: flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    -o-justify-content: flex-end;
    -ms-justify-content: flex-end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    cursor: pointer
}
@media screen and (min-width: 768px) {
    .header .header-top .accessblity-module {
        padding-right: 110px
    }
    .header .header-top{
        background-color: #000040;
    }
}
@media screen and (min-width: 992px) {
    .header .header-top .accessblity-module {
        padding-right: 130px
    }
}
@media screen and (min-width: 1200px) {
    .header .header-top .accessblity-module {
        padding-right: 150px
    }
}
@media screen and (min-width: 1600px) {
    .header .header-top .accessblity-module {
        padding-right: 190px
    }
}
@media screen and (min-width: 1920px) {
    .header .header-top .accessblity-module {
        padding-right: 200px
    }
}
.header .header-top .accessblity-module .front-resizer {
    display: flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    padding-right: 15px
}
.header .header-top .accessblity-module .front-resizer .increase,
.header .header-top .accessblity-module .front-resizer .reset,
.header .header-top .accessblity-module .front-resizer .decrease {
    font-size: 17px;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
    padding-left: 15px;
    cursor: pointer;
    line-height: 1.8
}
.header .header-top .contrast {
    position: relative;
    z-index: 99;
    margin-top: -3px
}
.header .header-top .contrast .contrast-switcher {
    height: 35px;
    display: flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: -webkit-box;
    display: -webkit-flex;
    align-items: center;
    -webkit-align-items: center;
    -ms-align-items: center;
    -o-align-items: center
}
.header .header-top .contrast .contrast-switcher .contrast-image {
    height: 18.6333px;
    width: 18.6333px;
    position: relative;
    cursor: pointer
}
.header .header-top .contrast .contrast-switcher .contrast-image svg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    color: #fff
}
.header .header-top .contrast .contrast-switcher .contrast-image .dark {
    opacity: 0
}
.contrast-on.header-fixed .header {
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
    background-color: #fff !important
}
.home .header {
    position: static;
    top: 0px
}
.header-fixed {
    transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out
}
.header-fixed .header {
    background-color: #000040;
    top: 0px;
    z-index: 99;
    position: fixed
}
.admin-bar .header {
    top: 0px;
    z-index: 99
}
@media screen and (min-width: 768px) {
    .admin-bar .header {
        top: 0px
    }
}
@media screen and (min-width: 992px) {
    .admin-bar .header {
        top: 0px
    }
}
.admin-bar.header-fixed .header {
    top: 46px
}
@media screen and (min-width: 768px) {
    .admin-bar.header-fixed .header {
        top: 46px
    }
}
@media screen and (min-width: 992px) {
    .admin-bar.header-fixed .header {
        top: 32px
    }
}