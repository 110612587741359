#toggle {
    display: none;
  }
.wprm-wrapper{
    display: none;
}
  /**
    Hamburger
  **/
  @media screen and (max-width: 767px) {
    .wprm-wrapper{
        display: block;
    }
  .hamburger {
    position: fixed;
    top: 5em;
    right: 6%;
    margin-left: -2em;
    margin-top: -25px;
    width: 1.8em;
    height: 40px;
    z-index: 999999;
  }
  
  .hamburger div {
    position: relative;
    width: 2.1em;
    height: 3.0px;
    border-radius: 3px;
    background-color: #ffffff;
    margin-top: 8px;
    transition: all 0.3s ease-in-out;
  }
  
  /**
  Nav Styles
  **/
  .nav {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #000040;
    top: -100%; left: 0; right: 0; bottom: 0;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    transform: scale(0);
    z-index: 999;
  }
  .nav-wrapper {
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    height: 100%;
  }
  nav {
    text-align: left;
    margin-left: 25%;
    margin-top: 20%;
  }
  nav a {
    position: relative;
    text-decoration: none;
    color: #ffffff;
    font-size: 1em;
    display: inline-block;
    margin-top: 2.25em;
    transition: color 0.2s ease-in-out;
    letter-spacing: 1px;
  }
  nav a:before {
    content: '';
    height: 0;
    position: absolute;
    width: 0.25em;
    background-color: white;
    left: -0.5em;
    transition: all 0.2s ease-in-out;
  }
  nav a:hover {
    color: white;
  }
  nav a:hover:before {
    height: 100%;
  }
  
  /**
  Animations
  **/
  #toggle:checked + .hamburger .top-bun {
    transform: rotate(-45deg);
    margin-top: 25px;
  }
  #toggle:checked + .hamburger .bottom-bun {
    opacity: 0;
    transform: rotate(45deg);
  }
  #toggle:checked + .hamburger .meat {
    transform: rotate(45deg);
    margin-top: -7px;
  }
  
  #toggle:checked + .hamburger + .nav {
    top: 0;
    transform: scale(1);
  }}